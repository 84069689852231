export const Video = () =>{
    return (
        <>
         <div className='main-page'>
         <div className="centered" style={{padding:'50px'}}>
         <iframe width="765" height="500" src="https://www.youtube.com/embed/4Ynx6GwYz5w" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

             </div>

         </div>
        </>
    )
}