export const URLS = [
    'https://ik.imagekit.io/magicman0007/IMG_2415_pgGJAZqlVuh.jpg?tr=w-600',
    'https://ik.imagekit.io/magicman0007/IMG_2400_lKPd1ETLC.jpg?tr=w-400',
    'https://ik.imagekit.io/magicman0007/IMG_2412_VH6rH7Lzfk5.jpg?tr=w-600',
    'https://ik.imagekit.io/magicman0007/IMG_2408_hVlbS8h5T.jpg?tr=w-600',
    'https://ik.imagekit.io/magicman0007/IMG_2418_feboQ_hFP.jpg?tr=w-600',
    'https://ik.imagekit.io/magicman0007/IMG_2396_6J3bdYTBr.jpg?tr=w-400',
    'https://ik.imagekit.io/magicman0007/IMG_2392_ZhLC2xIZA.jpg?tr=w-400',
    'https://ik.imagekit.io/magicman0007/IMG_2372_GvtPZWjc5.jpg?tr=w-400',
    'https://ik.imagekit.io/magicman0007/IMG_2424_gGdhQy4RL.jpg?tr=w-400',
    'https://ik.imagekit.io/magicman0007/IMG_2436_aCWpgRFm-mp.jpg?tr=w-600',
    'https://ik.imagekit.io/magicman0007/IMG_3319_LGnjUk2QJ.jpg?tr=w-600',
    'https://ik.imagekit.io/magicman0007/IMG_2428_ZYIc6fh6W.jpg?tr=w-600',
    'https://ik.imagekit.io/magicman0007/IMG_3269_KQZo9xWac.jpg?tr=w-600',
    'https://ik.imagekit.io/magicman0007/IMG_3329_kn-EQZaAe.jpg?tr=w-400',
    'https://ik.imagekit.io/magicman0007/IMG_3292_b_F1jkLFS.jpg?tr=w-400',
    'https://ik.imagekit.io/magicman0007/IMG_2482_L4MqQCjPk.jpg?tr=w-600',
    'https://ik.imagekit.io/magicman0007/IMG_3312_flF6ejTojRA.jpg?tr=w-400',
    'https://ik.imagekit.io/magicman0007/IMG_3014_7P_eR4iSA.jpg?tr=w-600',
    'https://ik.imagekit.io/magicman0007/IMG_2425_HAUI7bxoB.jpg?tr=w-600',
    'https://ik.imagekit.io/magicman0007/IMG_2951_H8VRr-Cw2.jpg?tr=w-600',
    'https://ik.imagekit.io/magicman0007/IMG_2854_kFgRF61-7QA.jpg?tr=w-600',
    'https://ik.imagekit.io/magicman0007/IMG_2445_gWfHI70Cy.jpg?tr=w-400',
    'https://ik.imagekit.io/magicman0007/IMG_3339_PCgbu9srOnT.jpg?tr=w-600',
    'https://ik.imagekit.io/magicman0007/IMG_2931__cpUKaj39.jpg?tr=w-600',
    'https://ik.imagekit.io/magicman0007/IMG_2945_iIm79mFEUJs.jpg?tr=w-600',
    'https://ik.imagekit.io/magicman0007/IMG_2844_WbDv2oMX50.jpg?tr=w-400',
    'https://ik.imagekit.io/magicman0007/IMG_2430_aLNbhS13N.jpg?tr=w-400',
    'https://ik.imagekit.io/magicman0007/IMG_2892_7DRvnspwo2.jpg?tr=w-400',
    'https://ik.imagekit.io/magicman0007/IMG_2528_3FbDZc-7m.jpg?tr=w-600',
    'https://ik.imagekit.io/magicman0007/IMG_2819_293fx_ddlr.jpg?tr=w-600',
    'https://ik.imagekit.io/magicman0007/IMG_2537_nr5GVPfzT.jpg?tr=w-600',
    'https://ik.imagekit.io/magicman0007/IMG_2967_mYBc43D20.jpg?tr=w-400',
    'https://ik.imagekit.io/magicman0007/IMG_2505_eIhtKl22x.jpg?tr=w-600',
    'https://ik.imagekit.io/magicman0007/IMG_2815_lvcjBzQe4.jpg?tr=w-600',
    'https://ik.imagekit.io/magicman0007/IMG_3248_vOWh2sB1-t.jpg?tr=w-600',
    'https://ik.imagekit.io/magicman0007/IMG_2970_mRM_GEpqv.jpg?tr=w-400',
    'https://ik.imagekit.io/magicman0007/IMG_2656_StbYJqkaMq.jpg?tr=w-600',
    'https://ik.imagekit.io/magicman0007/IMG_2506_gGECFNLM4H.jpg?tr=w-600',
    'https://ik.imagekit.io/magicman0007/IMG_2621_Ye7oI7kt681.jpg?tr=w-400',
    'https://ik.imagekit.io/magicman0007/IMG_2606_cGyfhuFB_.jpg?tr=w-600',
    'https://ik.imagekit.io/magicman0007/IMG_3695_djNhKe_7I.jpg?tr=w-400',
    'https://ik.imagekit.io/magicman0007/IMG_3430_vo8nkHs8P.jpg?tr=w-400',
    'https://ik.imagekit.io/magicman0007/IMG_3356_dipde56B6.jpg?tr=w-400',
    'https://ik.imagekit.io/magicman0007/IMG_3434_GeeosKNyQ.jpg?tr=w-400',
    'https://ik.imagekit.io/magicman0007/IMG_3719_Ek_I9qXAY.jpg?tr=w-600',
    'https://ik.imagekit.io/magicman0007/IMG_3487_Raj7nrtBV.jpg?tr=w-600',
    'https://ik.imagekit.io/magicman0007/IMG_3959_LnOrs4Tiz46.jpg?tr=w-400',
    'https://ik.imagekit.io/magicman0007/IMG_3985_NsDiv3c1X9n5.jpg?tr=w-600',
    'https://ik.imagekit.io/magicman0007/IMG_3851_ikILC-bz9.jpg?tr=w-600',
    'https://ik.imagekit.io/magicman0007/IMG_3694_Xmp3KdUja.jpg?tr=w-400',
    'https://ik.imagekit.io/magicman0007/IMG_3347__b9J_lksJSx.jpg?tr=w-400',
    'https://ik.imagekit.io/magicman0007/IMG_3706_g9by024ur.jpg?tr=w-400',
    'https://ik.imagekit.io/magicman0007/IMG_3841_DeoN2IBAa.jpg?tr=w-600',
    'https://ik.imagekit.io/magicman0007/IMG_3386_l61MxIAnC.jpg?tr=w-400',
    'https://ik.imagekit.io/magicman0007/IMG_3363_b4n598mHB.jpg?tr=w-400',
    'https://ik.imagekit.io/magicman0007/IMG_3368_6dLkPXp7y.jpg?tr=w-400',
    'https://ik.imagekit.io/magicman0007/IMG_3482_1BFmbzmikg.jpg?tr=w-600',
    'https://ik.imagekit.io/magicman0007/IMG_3440_ipRDYGwc6V.jpg?tr=w-400',
    'https://ik.imagekit.io/magicman0007/IMG_3836_AQ4PdT2hha.jpg?tr=w-600',
    'https://ik.imagekit.io/magicman0007/IMG_3381_LlFHPidMK.jpg?tr=w-600',
    'https://ik.imagekit.io/magicman0007/IMG_3844_AgRx9BGpH8.jpg?tr=w-600',
    'https://ik.imagekit.io/magicman0007/IMG_3473_N0FbGiHO7dW.jpg?tr=w-600',
    'https://ik.imagekit.io/magicman0007/IMG_3465_JEMcWWZG_Y8T.jpg?tr=w-600',
    'https://ik.imagekit.io/magicman0007/IMG_3573_FcrGhMiwf.jpg?tr=w-600',
    'https://ik.imagekit.io/magicman0007/IMG_3786_GcFnzSO1T.jpg?tr=w-600',
    'https://ik.imagekit.io/magicman0007/IMG_3743_4EjX8qyvYt.jpg?tr=w-400',
    'https://ik.imagekit.io/magicman0007/IMG_3413_U5913J5AEHig.jpg?tr=w-400',
    'https://ik.imagekit.io/magicman0007/IMG_3773_15BJy7d3R.jpg?tr=w-600',
    'https://ik.imagekit.io/magicman0007/IMG_3506_lKVH20qFIZ.jpg?tr=w-600',
    'https://ik.imagekit.io/magicman0007/IMG_3775_jyT8NbxK-.jpg?tr=w-600',
    'https://ik.imagekit.io/magicman0007/IMG_3757_-A4M3PfUTO.jpg?tr=w-400',
    'https://ik.imagekit.io/magicman0007/IMG_4696_6iDDURh9a.jpg?tr=w-600',
    'https://ik.imagekit.io/magicman0007/IMG_4129_U49Dtq7ArRk.jpg?tr=w-400',
    'https://ik.imagekit.io/magicman0007/IMG_4009_oDSKmPcaPjm.jpg?tr=w-600',
    'https://ik.imagekit.io/magicman0007/IMG_4001_LSdIpsrO9I4P.jpg?tr=w-400',
    'https://ik.imagekit.io/magicman0007/IMG_4461_0mwyUauDU.jpg?tr=w-600',
    'https://ik.imagekit.io/magicman0007/IMG_4598_qTiMBYu5kXx.jpg?tr=w-600',
    'https://ik.imagekit.io/magicman0007/IMG_4153_dceaxhUC5.jpg?tr=w-600',
    'https://ik.imagekit.io/magicman0007/IMG_4682_5S3_SVoGFW.jpg?tr=w-400',
    'https://ik.imagekit.io/magicman0007/IMG_4537_qCH1t48Hj.jpg?tr=w-600',
    'https://ik.imagekit.io/magicman0007/IMG_4508_mpVZnrC1I.jpg?tr=w-400',
    'https://ik.imagekit.io/magicman0007/IMG_4142_SXo26PXBZj.jpg?tr=w-400',
    'https://ik.imagekit.io/magicman0007/IMG_4391_dru6J1uXU.jpg?tr=w-400',
    'https://ik.imagekit.io/magicman0007/IMG_4546_GBUHaGfRvMl.jpg?tr=w-600',
    'https://ik.imagekit.io/magicman0007/IMG_5022_fKIlcgfa2.jpg?tr=w-600',
    'https://ik.imagekit.io/magicman0007/IMG_4515_kI79Uptbg.jpg?tr=w-600',
    'https://ik.imagekit.io/magicman0007/IMG_4501_k_SIfvBdu.jpg?tr=w-400',
    'https://ik.imagekit.io/magicman0007/IMG_4431_LY9rg_pDt.jpg?tr=w-400',
    'https://ik.imagekit.io/magicman0007/IMG_4416_i2kIBg0HQu.jpg?tr=w-400',
    'https://ik.imagekit.io/magicman0007/IMG_4947_TnaDYRB2F.jpg?tr=w-400',
    'https://ik.imagekit.io/magicman0007/IMG_5102_BjhZQUhZk.jpg?tr=w-400',
    'https://ik.imagekit.io/magicman0007/IMG_4874_xpevbsofal.jpg?tr=w-400',
    'https://ik.imagekit.io/magicman0007/IMG_5087_uUdNBke_mG.jpg?tr=w-600',
    'https://ik.imagekit.io/magicman0007/IMG_4769_oUi2p5m7C.jpg?tr=w-400',
    'https://ik.imagekit.io/magicman0007/IMG_4342_1k7EbpsQt.jpg?tr=w-400',
    'https://ik.imagekit.io/magicman0007/IMG_4719_W9OqFyK0I.jpg?tr=w-400',
    'https://ik.imagekit.io/magicman0007/IMG_4381_nz6n9sqs2w.jpg?tr=w-600',
    'https://ik.imagekit.io/magicman0007/IMG_4311_L1TcZhYgN.jpg?tr=w-600',
    'https://ik.imagekit.io/magicman0007/IMG_4330_w6jiGjVDpl.jpg?tr=w-400',
    'https://ik.imagekit.io/magicman0007/IMG_4584_LgRiFyTFw.jpg?tr=w-600',
    'https://ik.imagekit.io/magicman0007/IMG_5327_xfc0d6Eoh.jpg?tr=w-600',
    'https://ik.imagekit.io/magicman0007/IMG_5761_PvsX8jCaG.jpg?tr=w-400',
    'https://ik.imagekit.io/magicman0007/IMG_5837_g_NXR_PiA.jpg?tr=w-600',
    'https://ik.imagekit.io/magicman0007/IMG_6177_59YhFFe7Y9n.jpg?tr=w-400',
    'https://ik.imagekit.io/magicman0007/IMG_5466_GMQQHJ0Nu.jpg?tr=w-400',
    'https://ik.imagekit.io/magicman0007/IMG_5984_-9QeCo837.jpg?tr=w-600',
    'https://ik.imagekit.io/magicman0007/IMG_5778_YwXeGAxFq.jpg?tr=w-400',
    'https://ik.imagekit.io/magicman0007/IMG_5829_9cJ1AU-za.jpg?tr=w-600',
    'https://ik.imagekit.io/magicman0007/IMG_5877_nym1jYL88.jpg?tr=w-600',
    'https://ik.imagekit.io/magicman0007/IMG_5839_hq7WJqZEz.jpg?tr=w-600',
    'https://ik.imagekit.io/magicman0007/IMG_5399_86A4S4fCk.jpg?tr=w-400',
    'https://ik.imagekit.io/magicman0007/IMG_6034_C79sB2mPh.jpg?tr=w-600',
    'https://ik.imagekit.io/magicman0007/IMG_6056_ycLKSKPRNjA.jpg?tr=w-600',
    'https://ik.imagekit.io/magicman0007/IMG_5440_c2b1bi-eH.jpg?tr=w-600',
    'https://ik.imagekit.io/magicman0007/IMG_6085_KgP6gdGIEt.jpg?tr=w-600',
    'https://ik.imagekit.io/magicman0007/IMG_5806_hl7lXVgak.jpg?tr=w-600',
    'https://ik.imagekit.io/magicman0007/IMG_5591_pJrfaNzoQ.jpg?tr=w-600',
    'https://ik.imagekit.io/magicman0007/IMG_5668__VIe4B7JhqM.jpg?tr=w-600',
    'https://ik.imagekit.io/magicman0007/IMG_5520_m044ayZbM.jpg?tr=w-400',
    'https://ik.imagekit.io/magicman0007/IMG_5628_O-PfTN1iWX.jpg?tr=w-600',
    'https://ik.imagekit.io/magicman0007/IMG_5764_EB5eXxdC1.jpg?tr=w-400',
    'https://ik.imagekit.io/magicman0007/IMG_5962_IXCNpQy6Hg.jpg?tr=w-600',
    'https://ik.imagekit.io/magicman0007/IMG_5623_EV9yr1uMG.jpg?tr=w-600',
    'https://ik.imagekit.io/magicman0007/IMG_6024_SFWX6FAZL2c.jpg?tr=w-600',
    'https://ik.imagekit.io/magicman0007/IMG_6002_6kP6nkK9j.jpg?tr=w-600',
    'https://ik.imagekit.io/magicman0007/IMG_6163_qKKQmcaBX.jpg?tr=w-600',
    'https://ik.imagekit.io/magicman0007/IMG_6123_JQ5qPKnGs.jpg?tr=w-600',
    'https://ik.imagekit.io/magicman0007/IMG_6302_CTWpxXk39B.jpg?tr=w-400',
    'https://ik.imagekit.io/magicman0007/IMG_6126_SB4vJ-Ubk.jpg?tr=w-600',
    'https://ik.imagekit.io/magicman0007/IMG_5949_um3vgzxkNyP.jpg?tr=w-400',
    'https://ik.imagekit.io/magicman0007/IMG_6008_xxFIPeiSC.jpg?tr=w-600',
    'https://ik.imagekit.io/magicman0007/IMG_6133_JUc9HkwGZ.jpg?tr=w-600',
    'https://ik.imagekit.io/magicman0007/IMG_6332_dc9BKGo2XTJ.jpg?tr=w-400',
    'https://ik.imagekit.io/magicman0007/IMG_6322_h_Oh1Jx6U.jpg?tr=w-400',
    'https://ik.imagekit.io/magicman0007/IMG_6211_NU946pndpf.jpg?tr=w-400',
    'https://ik.imagekit.io/magicman0007/IMG_6225_xqIzHhAAO.jpg?tr=w-400',
    'https://ik.imagekit.io/magicman0007/IMG_6278_3WJ7NPlngtd.jpg?tr=w-600',
    'https://ik.imagekit.io/magicman0007/IMG_6192_PjM_3cTbLNy2.jpg?tr=w-600',
    'https://ik.imagekit.io/magicman0007/IMG_6258_wPitbB-0L.jpg?tr=w-600',
    'https://ik.imagekit.io/magicman0007/IMG_6251_9K0iyrx2X.jpg?tr=w-600',
    'https://ik.imagekit.io/magicman0007/IMG_6205_gSW-aY_KGV.jpg?tr=w-400',
    'https://ik.imagekit.io/magicman0007/IMG_4671_-VUqGU2kE.jpg?tr=w-400',
    'https://ik.imagekit.io/magicman0007/IMG_6411_9PyqtSkRg.jpg?tr=w-400',
    'https://ik.imagekit.io/magicman0007/IMG_6408_O5N8RixJM.jpg?tr=w-400,',
    'https://ik.imagekit.io/magicman0007/IMG_6391_0A65lxsoa.jpg?tr=w-600',
    'https://ik.imagekit.io/magicman0007/IMG_6596_QIGGotRwW.jpg?tr=w-400',
    'https://ik.imagekit.io/magicman0007/IMG_6569_zPozDzHodVa.jpg?tr=w-400',
    'https://ik.imagekit.io/magicman0007/IMG_6388_H1ztJHEG7DL.jpg?tr=w-400',
    'https://ik.imagekit.io/magicman0007/IMG_6436__LgCqFYDa.jpg?tr=w-400',
    'https://ik.imagekit.io/magicman0007/IMG_6499_W_dmoxnuvK.jpg?tr=w-400',
    'https://ik.imagekit.io/magicman0007/IMG_6689_evCKS08Oop.jpg?tr=w-600',
    'https://ik.imagekit.io/magicman0007/IMG_6759_qOwiTE2YZn.jpg?tr=w-600',
    'https://ik.imagekit.io/magicman0007/IMG_6733_ZikgvIgLX2m.jpg?tr=w-600',
    'https://ik.imagekit.io/magicman0007/IMG_6725_fG78Z4CMH.jpg?tr=w-600',
    'https://ik.imagekit.io/magicman0007/IMG_6552_6p0qOFaFP.jpg?tr=w-400',
    'https://ik.imagekit.io/magicman0007/IMG_6723_OLEhBq79C.jpg?tr=w-600',
    'https://ik.imagekit.io/magicman0007/IMG_6358_-qRWeO7fHfH.jpg?tr=w-400',
    'https://ik.imagekit.io/magicman0007/IMG_6770_hTDpGgFoS.jpg?tr=w-600',
    'https://ik.imagekit.io/magicman0007/IMG_6494_mWNxzrT50W.jpg?tr=w-400',
    'https://ik.imagekit.io/magicman0007/IMG_6537_IlgE0a-j42.jpg?tr=w-600',
    'https://ik.imagekit.io/magicman0007/IMG_6366_q-58EDRq23.jpg?tr=w-400'
]